import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ProcessSections from '../../utils/processSections'

const ListStyles = styled.div``

const SectionStyles = styled.div``

export default function AboutUs({ data }) {
  const sections = data.pageContent.nodes[0]

  return (
    <div className="content-width">
      <ProcessSections
        sections={sections}
        ListStyles={ListStyles}
        SectionStyles={SectionStyles}
      />
    </div>
  )
}

export const PageContent = graphql`
  query AboutContent {
    pageContent: allSanityPageContent(filter: { location: { eq: "/about" } }) {
      nodes {
        id
        title
        displayTitle
        content: _rawContent
      }
    }
  }
`
